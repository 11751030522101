import { onMounted, onUnmounted } from 'vue'
import { storeSettingGuard, storeSettingManager } from '~/libs/storeSetting'
import { notExistGuardStore } from '~/store/pinia/app-status-guard'
import { notExistManagerStore } from '~/store/pinia/app-status'

export function checkVisibilityChange(roleDiv: string) {
  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      // sessionStorageにStoreデータがない場合は再取得
      if (roleDiv === '1' && notExistManagerStore()) {
        storeSettingManager().finally(() => {
          window.location.reload()
        })
      }
      if (roleDiv === '3' && notExistGuardStore()) {
        storeSettingGuard('').finally(() => {
          window.location.reload()
        })
      }
    }
  }

  onMounted(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange)
  })

  onUnmounted(() => {
    document.removeEventListener('visibilitychange', handleVisibilityChange)
  })
}
